<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          About us
        </h1>
        <div class="article-body clearfix mb-3">
          <h2 class="mt-0">The world’s leading seafood and aquaculture news source</h2>
          <p>
            For over 20 years, IntraFish has led the world in seafood, fisheries and aquaculture news and business intelligence. We provide round-the-clock coverage of events as they happen and offer considered analysis on the industry’s biggest issues.
          </p>
          <p>
            Give your business the edge. Learn about the latest R&D innovations, market trends and key developments in the seafood and aquaculture sectors.<br>
            <a href="https://www.intrafish.com/subscription">Subscribe to IntraFish today</a>.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'aboutus-page',
  extends: Page
};
</script>
